<template>
  <v-container fluid pa-0>
    <v-row no-gutters class="fill-height">
      <v-col cols="12" sm="12" md="12" lg="12" class="fill-height">
        <v-card class="fill-height elevation-0 blue-grey lighten-5" tile >
          <v-card-title class="text-center justify-left py-6">
            <v-icon medium color="primary" class="pr-2">assignment</v-icon>
            <h1 class="font-weight-light headline">New Invoice</h1>
            <v-spacer></v-spacer>
            <v-icon @click="cancel">close</v-icon>
            <!-- <v-btn icon class="ml-2" color="purple" dark @click="downloadInvoice()">
              <v-icon>vertical_align_bottom</v-icon>
            </v-btn> -->
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row no-gutters class="mb-2">
              <v-col
                cols="12"
                sm="2"
                md="2"
                lg="2"
                class="align-self-center justify-center text-right pr-3"
              >
                <span class="black--text">Customer name *</span>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4" class="ml-4">
                <v-select
                  :items="customers"
                  item-text="first_name"
                  item-value="id"
                  label="Select Customer"
                  return-object
                  v-model="customerSelected"
                  @change="onCustomerSelect"
                  solo
                  flat
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-2">
              <v-col
                cols="12"
                sm="2"
                md="2"
                lg="2"
                class="align-self-center justify-center text-right pr-3"
              >
                <span class="black--text">Start Date *</span>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4" class="ml-4">
                <v-menu
                  v-model="startDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      hide-details
                      v-model="startDateValue"
                      label="Start Date"
                      append-icon="event"
                      readonly
                      v-on="on"
                      dense
                      solo
                      flat
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :show-current="true"
                    v-model="startDateValue"
                    @input="startDate = false"
                    :max="new Date().toISOString().substr(0, 10)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-2">
              <v-col
                cols="12"
                sm="2"
                md="2"
                lg="2"
                class="align-self-center justify-center text-right pr-3"
              >
                <span class="black--text">End Date *</span>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4" class="ml-4">
                <v-menu
                  v-model="endDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      hide-details
                      v-model="endDateValue"
                      label="End Date"
                      append-icon="event"
                      readonly
                      v-on="on"
                      dense
                      solo
                      flat
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDateValue"
                     @input="endDate = false"
                    :max="new Date().toISOString().substr(0, 10)"
                    :min="startDateValue"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-2">
              <v-col
                cols="12"
                sm="2"
                md="2"
                lg="2"
                class="align-self-center justify-center text-right pr-3"
              >
                <span class="black--text">Tariff *</span>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4" class="ml-4">
                <v-select
                  :items="tariffs"
                  item-text="name"
                  item-value="default_cost"
                  return-object
                  v-model="tariffSelected"
                  label="Select Tariff"
                  dense
                  solo
                  flat
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="pb-2">
              <v-col
                cols="12"
                sm="2"
                md="2"
                lg="2"
                class="align-self-center justify-center text-right pr-3"
              >
                <span class="black--text">Meters *</span>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4" class="ml-4">
                <v-combobox
                  v-model="selectedMeters"
                  :items="customerMeters"
                  item-text="name"
                  chips
                  clearable
                  label="Select Meters"
                  hide-details
                  dense
                  multiple
                  solo
                  flat
                >
                  <template v-slot:selection="{ attrs, item, select, selected, index }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="removeMeter(item)"
                      v-if="index === 0"
                    >
                      <span class="black--text">{{ item.name }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text caption"
                    >(+{{ selectedMeters.length - 1 }} others)</span>
                  </template>
                  <v-text-field
                    hide-details
                    v-model="endDateValue"
                    label="End Date"
                    append-icon="event"
                    readonly
                  ></v-text-field>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="2"
                md="2"
                lg="2"
                class="align-self-center justify-center text-right pr-3"
              >
                <span class="black--text">Due Date *</span>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4" class="ml-4">
                <v-menu
                  v-model="dueDate"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      hide-details
                      v-model="dueDateValue"
                      label="Due Date"
                      append-icon="event"
                      dense
                      readonly
                      solo
                      flat
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :show-current="true"
                    v-model="dueDateValue"
                    @input="dueDate = false"
                    :min="new Date().toISOString().substr(0, 10)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-4"> 
              <v-col cols="auto">
                <span class="black--text">Custom Fields</span>
              </v-col>
              <!-- <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-icon>info</v-icon>
              </v-col> -->
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters v-for="field in invoiceCustomFields" :key="field.id" class="pt-2">
              <v-col
                cols="12"
                sm="2"
                md="2"
                lg="2"
                class="align-self-center justify-center text-right pr-3"
              >
                <span class="black--text">{{field.name}}</span>
              </v-col>
              <v-col cols="auto" class="align-self-center text-md-center justify-center">
                <v-text-field
                  hide-details
                  v-model="field.default"
                  @change="onCustomFieldChange(field)"
                  label="Custom Field Value"
                  solo
                  dense
                  flat
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                class="align-self-center justify-center text-right pr-3"
              >
              <v-switch v-model="hide_custom_field" inset :label="`Hide custom fields in the generated invoice`"></v-switch>
              </v-col>
            </v-row>
            <!-- <v-col cols="auto">
        <div id="content" class="pa-4">
          <invoice-template></invoice-template>
        </div>
            </v-col>-->
            <!-- <v-row no-gutters class="pt-4">
              <v-col cols="auto">
                <v-btn @click="generateInvoice">Generate Invoice</v-btn>
                <v-btn class="ml-3" @click="downloadPDF">Download pdf</v-btn>
                <v-btn class="ml-3" color="red" @click="cancel">Cancel</v-btn>
              </v-col>
            </v-row>-->
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn @click="generateInvoice">Generate Invoice</v-btn>
            <!-- <v-btn class="ml-3" @click="downloadPDF">Download pdf</v-btn> -->
            <v-btn class="ml-3" color="red" @click="cancel">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!--  -->
    </v-row>
  </v-container>
</template>

<script>
// import Vue from "vue";
// import InvoiceTemplate from "../components/invoices/InvoiceTemplate";
import apiHelper from "../apiHelper";
import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import VueHtml2Canvas from "vue-html2canvas";

// Vue.use(VueHtml2Canvas);
export default {
  components: {
    // InvoiceTemplate
  },
  data() {
    return {
      show: false,
      customerSelected: {},
      tariffSelected: {},
      selectedMeters: [],
      output: null,
      startDateValue: null,
      startDate: false,
      endDateValue: null, //new Date().toLocaleDateString(),
      endDate: false,
      dueDateValue: null, //new Date().toLocaleDateString(),
      dueDate: false,
      updatedFields: [],
      customerMeters:[],
      hide_custom_field:false
    };
  },
  computed: {
    customers() {
      return this.$store.getters["MDMStore/getterCustomers"];
    },
    tariffs() {
      return this.$store.getters["MDMStore/getterOrgTariffs"];
    },
    // customerMeters() {
    //   return this.$store.getters["MDMStore/getterSelectedCustomerMeters"];
    // },
    invoiceCustomFields() {
      return this.$store.getters["MDMStore/getterOrgCustomFields"];
    }
  },
  beforeCreate() {},
  mounted() {
    this.$store.dispatch({
      type: "MDMStore/getCustomers"
    });
    this.$store.dispatch({
      type: "MDMStore/getOrgTariffs",
      orgId: localStorage.getItem("orgId")
    });
    this.$store.dispatch({
      type: "MDMStore/getOrgCustomFields",
      orgId: localStorage.getItem("orgId")
    });
  },
  methods: {
    async onCustomerSelect() {
      var self = this;
      
      await self.$store.dispatch({
        type: "MDMStore/getCustomerInfo",
        custId: self.customerSelected.id
      }).then(result =>{
          if(result.hasOwnProperty("meters") ){
           self.customerMeters = result.meters;
          }
        })
      self.selectedMeters = [];
    },
    async generateInvoice() {
      var self = this;
      var metersData = self.selectedMeters.map(function(meter) {
        return { meterId: meter.serial_number };
      });
      var postData = {
        custId: self.customerSelected.id,
        startDate: self.startDateValue + "T00:00:00.000Z",
        endDate: self.endDateValue + "T23:59:00.000Z",
        dueDate: self.dueDateValue,
        tariffId: self.tariffSelected.id,
        selectedMeters: metersData,
        customFields: self.updatedFields,
        hide_custom_field:self.hide_custom_field
      };
      var result = await apiHelper(
        "POST",
        "/mdm/api/generateInvoice",
        postData
      );
      if (
        result.data.hasOwnProperty("success") &&
        result.data.success == true
      ) {
        self.$store.dispatch({
          type: "MDMStore/getInvoices",
          orgId: localStorage.getItem("orgId")
        });
        this.$router.push({ path: "/invoices/invoice/"+result.data.invoice_id });
      }
    },
    removeMeter(item) {
      this.selectedMeters.splice(this.selectedMeters.indexOf(item), 1);
      this.selectedMeters = [...this.selectedMeters];
    },
    onCustomFieldChange(field) {
      if (this.updatedFields.find(f => f.custom_field_idx == field.id)) {
        Object.assign(
          this.updatedFields.find(f => f.custom_field_idx == field.id),
          { custom_field_value: field.default }
        );
      } else {
        this.updatedFields.push(
          Object.assign(
            {},
            { custom_field_idx: field.id, custom_field_value: field.default }
          )
        );
      }
    },
    cancel() {
      this.$router.push({ path: "/invoices" });
    }
  }
};
</script>
<style scoped>
.v-text-field {
  border: 1px solid darkgrey;
}
</style>
